export const tags = {
    sectionTitle: '',
    icon: null,
    list: [
        'Linux Server',
        'RedHat',
        'Ubuntu',
        'Bash',
        'Python',
        'Groovy',
        'Docker',
        'Kubernetes',
        'Helm',
        'Terraform',
        'Swarm',
        'Amazon Web Services',
        'CI/CD',
        'Jenkins',
        'Ansible',
        'Nagios',
        'DataDog',
        'Consul',
        'Maven',
        'Artifactory',
        'ELK',
        'NewRelic',
        'Nginx',
        'Apache',
        'MySQL',
        'Redis',
        'MongoDB',
        'GitHub',
        'GitLab',
        'VMware',
        'Windows Server',
        'Cisco',
        'Javascript',
        'ReactJS',
        'NodeJS'
    ]
};

export default tags;
